<template>
  <v-content>
    <v-layout></v-layout>
    <v-card flat>
      <v-card-title >
        <b>COMING SOON</b>
        <v-spacer></v-spacer>
      </v-card-title>
    </v-card>
  </v-content>
</template>

<script>

  export default {
    name: 'about',
    components: {
    }
  }
</script>