<template>
    <v-content>
        <v-layout></v-layout>
        <v-card flat>
            <v-card-title >
                <b>Software Engineer I</b>
                <v-card-subtitle> at HomeX</v-card-subtitle>
                <v-spacer></v-spacer>
                Sept. 2021 - Feb. 2022
            </v-card-title>
            <v-card-text>
                <v-list align="left">
                    <li>Took ownership of automated testing pipeline in <b>Cypress</b> with <b>>80% code coverage</b></li>
                    <li>Implemented <b>Kafka</b> processing pipeline for consuming queued events and aggregating metrics</li>
                    <li>Led initiative to revamp error handling in <b>TypeScript</b> application</li>
                    <li>Developed queries and mutations for <b>GraphQL API</b></li>
                </v-list>
            </v-card-text>
        </v-card>
        <v-card flat>
            <v-card-title >
                <b>Software Architect</b>
                <v-card-subtitle> at Baron Biosystems</v-card-subtitle>
                <v-spacer></v-spacer>
                May 2020 - Aug. 2020
            </v-card-title>
            <v-card-text>
                <v-list align="left">
                    <li>Updated account management processes in <b>MongoDB</b> and <b>PHP</b> web applications to enable users to have multiple accounts</li>
                    <li>Leveraged <b>REST APIs</b> to automatically sync activity data from Garmin devices into Baron's fitness analytics platform</li>
                    <li>Incorporated an open source chat platform into web and mobile applications</li>
                    <li>Designed front-end components and developed testing scripts</li>
                </v-list>
            </v-card-text>
        </v-card>
        <v-card flat>
            <v-card-title >
                <b>Software Engineer - Service Oriented Platform</b>
                <v-card-subtitle> at PointClickCare</v-card-subtitle>
                <v-spacer></v-spacer>
                Sept. 2019 - Dec. 2019
            </v-card-title>
            <v-card-text>
                <v-list align="left">
                    <li>Built user interfaces in <b>Vue</b> adhering to <b>Material Design</b> principles</li>
                    <li>Created and maintained APIs on <b>Spring</b> backend pertaining to user management using</li>
                    <li>Packaged all code into <b>Docker</b> containers for deployment</li>
                    <li>Wrote automated integration tests and increased coverage of testing pipeline</li>
                    <li>Implemented automated data collection and report generation using <b>Amazon Web Services</b></li>
                </v-list>
            </v-card-text>
        </v-card>
        <v-card flat>
            <v-card-title>
                <b>Software Engineer - Core</b>
                <v-card-subtitle> at PointClickCare</v-card-subtitle>
                <v-spacer></v-spacer>
                Jan. 2019 - Apr. 2019
            </v-card-title>
            <v-card-text>
                <v-list align="left">
                    <li>Wrote complex <b>SQL</b> stored procedures and queries for database operations regarding <b>Customer Relationship Management</b></li>
                    <li>Participated in design discussions pertaining to core application</li>
                    <li>Integrated with third party APIs and produced documentation to be used by other developers</li>
                    <li>Worked <b>Agile</b> in 2 week sprints</li>
                </v-list>
            </v-card-text>
        </v-card>
        <v-card flat>
            <v-card-title>
                <b>Engineering Intern</b>
                <v-card-subtitle> at Keltech Ltd.</v-card-subtitle>
                <v-spacer></v-spacer>
                May 2018 - Aug. 2018
            </v-card-title>
            <v-card-text>
                <v-list align="left">
                    <li>Created 3D parts and assemblies according to customer specifications using <b>Autodesk Inventor</b></li>
                    <li>Performed shipping, receiving, as well as administrative duties pertaining to existing clients</li>
                    <li>Assisted in the maintenance of electric motors</li>
                    <li>Visited plants and assembled gearboxes for use on conveyors</li>
                </v-list>
            </v-card-text>
        </v-card>
        <v-card flat>
            <v-card-title>
                <b>Virtual Reality & Software Developer</b>
                <v-card-subtitle> at Ctrl V</v-card-subtitle>
                <v-spacer></v-spacer>
                Jan. 2017 - Dec. 2017
            </v-card-title>
            <v-card-text>
                <v-list align="left">
                    <li>Led the development of dynamic VR user interface used by <b>100%</b> of Ctrl V customers</li>
                    <li>Performed <b>User Testing</b> with customers and iterated accordingly</li>
                    <li>Supervised the development of a <b>Point-of-Sale</b> software using <b>Qt</b> and <b>C++</b> which is used at more than <b>30 arcade locations</b></li>
                    <li>Responded to bug reports and assisted franchisees with IT related issues</li>
                </v-list>
            </v-card-text>
        </v-card>
        <v-card flat>
            <v-card-title>
                <b>Data Entry</b>
                <v-card-subtitle> at Sandler Training</v-card-subtitle>
                <v-spacer></v-spacer>
                Feb. 2016 - Aug. 2016
            </v-card-title>
            <v-card-text>
                <v-list align="left">
                    <li>Increased prospecting efficiency by leveraging multiple research and communication channels</li>
                    <li>Assisted with customer support</li>
                    <li>Created and managed <b>Excel</b> spreadsheets pertaining to new sales and existing accounts</li>
                </v-list>
            </v-card-text>
        </v-card>
        <v-card flat>
            <v-card-title>
                <b>Carpenter Assistant</b>
                <v-card-subtitle> at Burke Construction</v-card-subtitle>
                <v-spacer></v-spacer>
                Apr. 2015 - Sept. 2015
            </v-card-title>
            <v-card-text>
                <v-list align="left">
                    <li>Assisted Red Seal carpenters throughout the renovation process from demolition to finishing</li>
                    <li>Performed basic carpentry in a residential environment</li>
                    <li>Maintained job site to ensure safety and cleanliness for clients and colleagues</li>
                </v-list>
            </v-card-text>
        </v-card>

    </v-content>
</template>

<script>
    export default {
        name: "Resume"
    }
</script>

<style scoped>

</style>